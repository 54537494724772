@import "./styles/fonts.pcss";

#root {
    position: relative;
    width: 100%;
    overflow-x: hidden;
    min-height: 100vh;
}

html, body {
    margin: 0;
    height: 100%;
    overflow-x: hidden;
    padding: 0;
    width: 100%;
    background-color: white;
}

.content {
    display: flex;
    min-height: 50vh;
    justify-content: center;
}

h1 {
    font-size: 38px;
    @mixin font-RobotoSlab;
    font-weight: initial;
}

h2 {
    font-size: 26px;
    @mixin font-RobotoSlab;
    font-weight: initial;
}

h3 {
    font-size: 24px;
    @mixin font-RobotoSlab;
    font-weight: initial;
}

h4 {
    font-size: 20px;
    @mixin font-RobotoSlab;
    font-weight: initial;
}

h5 {
    font-size: 18px;
    @mixin font-RobotoSlab;
    font-weight: initial;
}

h6 {
    font-size: 17px;
    @mixin font-RobotoSlab;
    font-weight: initial;
}

button {
    font-size: 17px;
    @mixin font-Rubik;
    color: inherit;
}

p {
    @mixin font-Rubik;
}

.inc-back-color {
    background: $color_inkraslav;
}

.inv-back-color {
    background: $color_invades;
}

.row {
    display: flex;
}

.column {
    display: flex;
    flex-direction: column;
}
