
$header_height: 60px;

.header_logo-slider {
    display: flex;
    z-index: 4;
    overflow: hidden;
    position: relative;
    min-width: 165px;
    height: $header_height;
    margin-right: 2px;
}

.header_logo-slides li {
    transition: all 0.3s ease-in-out;
    display: flex;
    position: absolute;
    left: -100%;
}

.header_logo-slides li.active {
    transition: all 0.3s ease-in-out;
    top: 0;
    left: 0;
}

.header_small-description h5 {
    font-size: $fontSize_standard;
    @mixin font-Rubik;
    white-space: nowrap;
    color: white;
    margin: 0;
}

.logo-wrapper {
    overflow: hidden;
    min-width: 100%;
    height: $header_height;
    position: relative;
}

.header_logo-slides {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;
    padding: 0;
    margin: 0;
}

.header_logo-slider li{
    display: flex;
    width: 100%;
    height: $header_height;
}

.header_logo-slider h2 {
    text-align: right;
    color: white;
    align-items: center;
    width: 100%;
    text-transform: uppercase;
    line-height: $header_height;
    margin: 0;
}

@media (max-width: 960px) {

}
