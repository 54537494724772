
$field_height: 40px;

.search-fld {
    display: flex;
    position: relative;
    justify-content: flex-end;
    height: 40px;
    padding: 5px 10px;
}

.search-fld .search-fld_content {
    display: flex;
    position: relative;
    justify-content: flex-end;
    width: 40px;
    height: 40px;
    max-height: 40px;
    z-index: 9;
}

.search-fld_content input {
    display: flex;
    outline: none;
    border: none;
    border-radius: 0;
    padding: 0 20px;
    font-size: 15px;
    height: 40px;
    min-height: 40px;
    width: 0;
    box-sizing: border-box;
    transition: width 0.3s ease-in-out;
    z-index: 5;
}

.search-fld_content button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    font-size: 24px;
    background: white;
    width: 38px;
    height: 38px;
    top: 1px;
    margin:  auto 0;
    padding: 0;
    border: none;
    right: 0;
    outline: none;
    transition: all 0.3s ease-in-out;
    z-index: 6; // higher than absolute mobile input
}

.search-fld_content input.focused {
    min-width: 300px;
    max-width: 500px;
    width: 100%;
    background-color: white;
}

.search-fld_content input::placeholder {
    @mixin font-Rubik-light;
    font-size: $fontSize_superMedium;
    color: $grey900;
}

.search-fld_options.disabled {
    display: none;
}

.search-fld_options {
    position: absolute;
    max-width: 650px;
    top: calc($field_height + 10px);
    background: white;
    margin: 0;
    padding: 0;
    z-index: 10;
    box-shadow: 1px 3px 7px 0 rgba(0,0,0,0.4);
}

.search-fld_options li  {
    display: flex;
    z-index: 10;
    max-height: 76px;
    height: 76px;
    padding: 15px 0;
    margin: 0 10px;
    list-style-type: none;
}

.search-fld_options li:hover {
    cursor: pointer;
}

.search-fld_options li .search_link {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow: hidden;
    max-height: 76px;
    height: 76px;
    width: 500px;
    border-radius: 0;
    text-decoration: none;
    border-bottom: 1px solid #cfd8dc;
}

.search_label {
    display: inline;
}

.search_label,.search_addition {
    display: inline;
    line-height: 13px;
    margin: 0;
    text-align: left;
    margin-left: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.search_addition {
    display: inline;
    @mixin font-Rubik-light;
    font-size: $fontSize_small;
}

.search_company {
    padding: 0 3px;
    margin: 0;
    color: $grey900;
}

.search_link > .row {
    justify-content: space-between;
}

.search-fld_options .no-options-wrapper {
    display: flex;
    width: 500px;
    height: 90px;
    padding: 0;
    justify-content: center;
    align-content: center;
}

.search_prod-img {
    width: 70px;
    height: 76px;
}

.search-name {
    color: $grey900;
    margin: 0 10px;
    text-align: left;
}

@media (max-width: 960px) {
    .header_search.search-fld {
        margin: 0;
        padding: 0 10px;
    }

    .search-fld .search-fld_content {

    }

    .search-fld_content input.focused {
        width: calc(100vw - 20px);
        position: absolute;
    }

    .search-fld_options {
        width: 100vw;
        min-width: 100vw;
        max-width: 100vw;
        border-bottom: 1px solid $grey200;
        box-shadow: none;
        right: 0;
    }

    .no-options-wrapper {
        max-width: 100vw;
    }
}
