@font-face {
    font-family: 'RobotoSlab';
    font-weight: normal;
    font-style: normal;
    src:
        local('RobotoSlab'),
        url("/fonts/RobotoSlab-VariableFont_wght.ttf") format('truetype');
}

@font-face {
    font-family: 'Rubik';
    font-weight: normal;
    font-style: normal;
    src:
        local('Rubik'),
        url("/fonts/Rubik-VariableFont_wght.ttf") format('truetype');
}

@font-face {
    font-family: 'Rubik-light';
    font-weight: 100;
    font-style: normal;
    src:
            local('Rubik-light'),
            url("/fonts/Rubik-VariableFont_wght.ttf") format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    src:
            local('Montserrat'),
            url("/fonts/Montserrat-Regular.ttf") format('truetype');
}

@define-mixin font-RobotoSlab {
    font-family: "RobotoSlab", sans, sans-serif;
}

@define-mixin font-Rubik {
    font-family: "Rubik", sans, sans-serif;
}

@define-mixin font-Rubik-light {
    font-family: "Rubik-light", sans, sans-serif;
}

@define-mixin font-Montserrat {
    font-family: "Montserrat", sans, sans-serif;
}

/*
@define-mixin isIE {
    .isIE & {
        @mixin-content;
    }
}
*/
