.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  background-color: $grey900;
  padding-bottom: 40px;
}

.footer_content {
  max-width: 1280px;
  flex-grow: 1;
  justify-content: space-around;
  display: flex;
}

.footer-block {
  display:flex;
  flex-direction:column;
  flex-grow: 1;
  align-items: center;
  position: relative;
  padding-top: 30px;
}

.footer_logo-text h1{
  margin-bottom: 20px;
  color: #0091EA;
}

.footer_var {
  margin-bottom: 8px;
  color: #0091EA;
}

.footer_text h4 {
  @mixin font-Montserrat;
  margin-bottom: 4px;
  color: white;
}

@media (max-width: 960px) {
  .footer {
    flex-direction: column;
  }

  .footer:last-child {
    padding-bottom: 15px;
  }

  .footer_content {
    flex-direction: column;
  }
}
