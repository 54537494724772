.chooser {
  min-width: 250px;
  width: 250px;
}

.chooser_content {
  margin: 0 auto;
}

.chooser_content.isFloating {
  top: 0;
  position: fixed;
}

.chooser_tabs {
  display: flex;
  min-width: 240px;
  top: 0;
  list-style: none;
  margin: 0;
  padding: 0;
}

.chooser_tabs li {
}

.chooser_tabs li button {
  border: 0 solid transparent;
  cursor: pointer;
  color: white;
  position: relative;
  display: block;
  width: 118px;
  height: 48px;
  @mixin font-RobotoSlab;
  text-align: center;
  text-transform: uppercase;
}

.chooser_tabs li button:first-child {
  margin-right: 3px;
}

.chooser_tabs button {
  transition: all 0.3s ease-in-out;
}

.chooser_tabs .active button {
  color: white;
  z-index: 4;
  outline: none;
}

.chooser_tabs .active button.inc-back-color {
  background: $color_inkraslav;
}

.chooser_tabs .active button.inv-back-color {
  background: $color_invades;
}

.chooser_tabs button:hover {
  top: 3px;
}

.chooser_tabs .default button {
  background: $grey900;
}

.chooser_tabs li:first-child button {
  z-index: 4;
  box-shadow: -1px 3px 7px 0 rgba(0,0,0,0.4);
}

.chooser_tabs li:last-child button {
  z-index: 1;
  box-shadow: 1px 3px 7px 0 rgba(0,0,0,0.4);
}

@media (max-width: 960px) {
  .chooser {
    min-height: 35px;
    height: 35px;
  }

  .chooser_tabs {
    margin-top: 2px;
  }

  .chooser_tabs li button {
    height: 35px;
  }
}
