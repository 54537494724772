$header_height: 60px;

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  max-width: 100%;
}

.header_hr {
  display: flex;
  width: 100%;
  margin: 0;
  height: 2px;
  border: 0;
}

.header_logo-line {
  display: flex;
  text-align: center;
  align-items: center;
  max-width: 1280px;
  width: 1100px;
  height: $header_height;
  background-color: $grey900;
}

.header_logo-line button:visited, button:focus, button:active, button:hover{
  outline: none;
}

.header_logo-line button {
  border: none;
}

.nav-element a {
  white-space: nowrap;
  overflow: hidden;
}

.header_logo-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  z-index: 5;
}

.header_logo-wrapper img{
  display: flex;
  align-items: center;
  padding: 0 10px;
  position: relative;
  max-width: 40px;
  max-height: 40px;
  z-index: 5;
}

.header_search {
  margin-left: auto;
}

@media (max-width: 960px) {
  .header_small-description {
    display: none;
  }

  .header_logo-line {
    width: 100%;
    justify-content: space-between;
  }
}


