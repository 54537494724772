$nav-menu-height: 35px;

.nav-menu {
  display: flex;
  justify-items: center;
  justify-content: center;
  position: relative;
  z-index: 4;
  min-height: $nav-menu-height;
}

.nav-menu_content {
  display: flex;
  position: relative;
  justify-content: space-between;
  text-align: center;
  background-color: white;
  z-index: 3;
  height: $nav-menu-height;
}

.nav-menu_content.float {
  position: fixed;
  top: 0;
  background: white;
  margin: 0 auto;
  padding-top: 10px;
}

.nav-menu_content.fixed {
  position: relative;
  margin: 0 auto;
  padding-top: 10px;
}

.nav-menu_element a {
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  cursor: pointer;
  color: $grey800;
}

.nav-menu_element:hover a {
  color: $grey700;
  transition: .5s;
}

.nav-menu_element {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 4;
  line-height: $nav-menu-height;
  padding: 0 10px 0 10px;
}

.nav-menu_element h4 {
  margin: 0;
}

.nav-menu_indicator {
  width: 100%;
  min-height: 2px;
}

.nav-menu_indicator.inc-back-color:not(.active),.nav-menu_indicator.inv-back-color:not(.active) {
  background: transparent;
}

@media (max-width: 960px) {
  .nav-menu-mob {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    height: unset;
    z-index: 4;
  }

  .nav-menu-mob .nav-menu_element {
    padding: 0 4px;
    margin-right: 1px;
    margin-top: 1px;
    background: $grey100;
  }

  .nav-menu-mob .nav-menu_element h4 {
    font-size: $fontSize_medium;
  }
}



